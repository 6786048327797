import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { articles } from "../content/Articles";
import blogImg from "../assets/pictures/BlogImg.png";
import styled from "styled-components";
import BlogSection from "../containers/BlogSection";
import QrCodeSection from "../containers/QrCodeSection";
import bgShape from "../assets/bgShapeQr.svg";
import FooterSection from "../containers/FooterSection";
import { Helmet } from "react-helmet";

function BlogDetails() {
  const { id } = useParams();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);
  const fullArticle = articles.find((article) => {
    return article.id == id;
  });
  const lastArticles = articles.slice(-2);

  return (
    <Wrapper>
      <Helmet>
        <title>{fullArticle.title}</title>
        <meta name="description" content={fullArticle.desc} />
        <meta
          name="keywords"
          content="Application mobile, permis en france, Gérer Mon Permis, suivi des points de permis, vérification des points de permis, consultation des points de permis, gestion des infractions de conduite, récupération des points de permis, renouvellement du permis de conduire, suspension du permis de conduire, permis de conduire en ligne, historique des infractions de conduite, alerte de perte de points, paiement des amendes, amendes de stationnement, amendes pour excès de vitesse, gestion des contraventions, contestation d'amende, consultation des amendes, suivi des amendes, paiement en ligne des amendes, OCR des amendes, scanner des amendes, rappel entretien véhicule, gestion de l'entretien du véhicule, rappel vidange voiture, rappel contrôle technique, gestion des documents du véhicule, historique d'entretien du véhicule, suivi de l'entretien automobile, planification des entretiens de voiture, gestion de flotte de véhicules, alerte maintenance voiture, application de gestion de permis, application de gestion de véhicules, application de suivi des amendes, sécurité routière, code de la route, conseils de conduite, gestion des infractions, gestion des documents de véhicule, application mobile de gestion de permis, solution digitale pour conducteurs"
        />
      </Helmet>
      <SectionWrapper>
        {isMobile && (
          <>
            <ArticleTitle>{fullArticle.title}</ArticleTitle>
            <ArticlePicture src={blogImg} />
          </>
        )}
        <ArticleTxtBody>
          {!isMobile && (
            <>
              <ArticleTitle>{fullArticle.title}</ArticleTitle>
              <ArticlePicture src={blogImg} />
            </>
          )}
          {fullArticle.fullDesc}
        </ArticleTxtBody>
      </SectionWrapper>
      <LastArticlesTitle>Derniers articles</LastArticlesTitle>
      <BlogSection articles={lastArticles} style={{ marginTop: 0 }} />
      <QrCodeSection bgShape={bgShape} />
      <FooterSection />
    </Wrapper>
  );
}

export default BlogDetails;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  //justify-content: center;
  margin-top: 64px;
  width: 90%;
  gap: 16px;

  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const ArticleTitle = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.8px;
  text-align: start;
  margin-top: 16px;
  @media (max-width: 780px) {
    font-size: 32px;
    line-height: 40px;
  }
`;
const ArticleTxtBody = styled.p`
  color: #222426;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: justify;
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const ArticlePicture = styled.img`
  float: inline-end;
  margin: 16px;
  width: 50%;
  aspect-ratio: 1.3;
  @media (max-width: 780px) {
    align-self: stretch;
    width: auto;
    float: none;
    margin: 16px 0;
  }
`;

const LastArticlesTitle = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.64px;
  text-align: start;
  align-self: start;
  margin-left: 64px;
  margin: 0;
  margin-left: 64px;
  margin-top: 100px;
`;
