import React from "react";
import styled from "styled-components";
// import googleplayIcon from "../assets/icons/googleplay.svg";
// import appleIcon from "../assets/icons/apple.svg";
import whiteLogo from "../assets/icons/whiteLogo.svg";
import { palette } from "../styles/globalStyle";
import {
  InstagramLogo,
  LinkedinLogo,
  FacebookLogo,
  XLogo,
} from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

function FooterSection() {
  const navigate = useNavigate();
  const sections = [
    {
      title: "Features",
      links: [
        {
          label: "Visualisez vos points",
          onClick: () => {
            navigate(`/?featureId=1`);
          },
        },
        {
          label: "Organisation des amendes",
          onClick: () => {
            navigate(`/?featureId=2`);
          },
        },
        {
          label: "Gestion des amendes",
          onClick: () => {
            navigate(`/?featureId=3`);
          },
        },
        {
          label: "Gestion des véhicules",
          onClick: () => {
            navigate(`/?featureId=4`);
          },
        },
      ],
    },
    {
      title: "Sections",
      links: [
        {
          label: "À propos de nous",
          onClick: () => {
            navigate(`/?sectionId=aboutUs`);
          },
        },
        {
          label: "Fonctionnalités",
          onClick: () => {
            navigate(`/?sectionId=features`);
          },
        },
        {
          label: "Témoignages",
          onClick: () => {
            navigate(`/?sectionId=reviews`);
          },
        },
        {
          label: "Utilisation",
          onClick: () => {
            navigate(`/?sectionId=howToUse`);
          },
        },
        {
          label: "Blog",
          onClick: () => {
            navigate(`/?sectionId=blog`);
          },
        },
        {
          label: "Questions fréquentes",
          onClick: () => {
            navigate(`/?sectionId=F&Q`);
          },
        },
      ],
    },

    {
      title: "Sociaux",
      links: [
        {
          label: "Instagram",
          icon: (
            <InstagramLogo
              style={{ margin: "4px 8px 4px 0" }}
              color={palette.Primary[500]}
              size={48}
            />
          ),
          onClick: () => {
            handleOpenUrl("https://www.instagram.com/gerermonpermis.app/");
          },
        },
        {
          label: "Facebook",
          icon: (
            <FacebookLogo
              style={{ margin: "4px 8px 4px 0" }}
              color={palette.Primary[500]}
              size={48}
            />
          ),
          onClick: () => {
            handleOpenUrl(
              "https://www.facebook.com/people/G%C3%A9rermonpermis/61561998237437/"
            );
          },
        },
        // {
        //   label: "Linkedin",
        //   icon: (
        //     <LinkedinLogo
        //       style={{ margin: "4px 8px" }}
        //       color={palette.Primary[500]}
        //       size={48}
        //     />
        //   ),
        //   onClick: () => {},
        // },

        {
          label: "X (Twitter)",
          icon: (
            <XLogo
              style={{ margin: "4px 8px" }}
              color={palette.Primary[500]}
              size={48}
            />
          ),
          onClick: () => {
            handleOpenUrl(
              "https://x.com/GererMonPermis"
            );
          },
        },
        // {
        //   label: "Linkedin",
        //   icon: (
        //     <LinkedinLogo
        //       style={{ margin: "4px 8px" }}
        //       color={palette.Primary[500]}
        //       size={48}
        //     />
        //   ),
        //   onClick: () => {},
        // },

        // {
        //   label: "YouTube",
        //   icon: (
        //     <YoutubeLogo
        //       style={{ margin: "4px 8px" }}
        //       color={palette.Primary[500]}
        //       size={48}
        //     />
        //   ),
        //   onClick: () => {},
        // },
      ],
    },
  ];
  const handleOpenUrl = (url) => {
    window.open(url, "_blank");
  };
  return (
    <Wrapper>
      <DownloadWrapper>
        <AppLogoWrapper>
          <Logo src={whiteLogo} />
          <AppTitle>GérerMonPermis</AppTitle>
        </AppLogoWrapper>
      </DownloadWrapper>
      <SectionsWrapper>
        {sections.map((section, index) => (
          <SectionWrapper key={index}>
            <SectionTitle>{section.title}</SectionTitle>
            {section.links.map((link, index) => (
              <LinkWrapper onClick={link.onClick}>
                {link.icon}
                <Link key={index}>{link.label}</Link>
              </LinkWrapper>
            ))}
          </SectionWrapper>
        ))}
      </SectionsWrapper>
      <CopyrightTxt>GérerMonPermis@Copyright</CopyrightTxt>
    </Wrapper>
  );
}

export default FooterSection;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: #c7def9;
  align-items: center;
  flex-direction: column;
  @media (max-width: 780px) {
    padding-top: 60px;
  }
`;

const DownloadWrapper = styled.div`
  display: flex;
  width: 80%;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  border-radius: 32px;
  background-color: #c7def9;
  margin-top: 40px;
  flex-direction: column;
  @media (max-width: 780px) {
    width: 90%;

    border: 0;
    margin-top: 0;
    padding: 8px;
  }
`;

const AppTitle = styled.p`
  color: #1776e5;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  @media (max-width: 460px) {
    color: #1776e5;
    font-family: Roboto;
    font-size: 21.971px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

const AppLogoWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const SectionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 50%;
  }
  @media (max-width: 500px) {
    width: 100%;
    align-items: center;
  }
`;
const LinkWrapper = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  &:hover {
    background-color: #9bc4f4; // Default hover color
  }
`;
const SectionTitle = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.56px;
  text-align: start;
  @media (max-width: 600px) {
    color: #0a3567;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.4px;
  }
`;

const Link = styled.a`
  margin: 4px 0;
  color: #1776e5;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: start;
  @media (max-width: 600px) {
    color: #1776e5;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
`;

const CopyrightTxt = styled.p`
  color: #1776e5;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 40px;
`;

const Logo = styled.img`
  width: 120px;
  aspect-ratio: 1;
  @media (max-width: 780px) {
    width: 80px;
  }
`;
