export const articles = [
  {
    id: 1,
    title: "Top 5 des infractions méconnues",
    desc: "Vous pensez connaître toutes les façons de perdre des points ? Surprendre! Cet article révèle 5 infractions cachées qui drainent des points et partage des conseils pour...",
    fullDesc: `          Comment éviter les pièges à points : Top 5 des infractions méconnues
          Unde maiores iusto architecto repellat veniam expedita unde nihil.
          Voluptatem in dolorem rerum ex consequatur. In quod ducimus. Quibusdam
          illo reiciendis. Maiores rem distinctio aut dolorem id dolorum rerum.
          Necessitatibus ut occaecati illo enim eum delectus eos. Vel ex
          perspiciatis corrupti eaque. Quis eligendi quae possimus natus enim
          eum aut quae. Fuga consequatur et. Iusto debitis quam fuga nulla
          eveniet quos sed. Nulla incidunt ut illum sed velit voluptatem.
          Tempora saepe quis dolore corporis. Qui rerum voluptatum hic quae
          nihil. Architecto deserunt odio nostrum molestiae. Molestiae soluta
          nemo. Necessitatibus ut occaecati illo enim eum delectus eos. Vel ex
          perspiciatis corrupti eaque. Quis eligendi quae possimus natus enim
          eum aut quae. Fuga consequatur et. Iusto debitis quam fuga nulla
          eveniet quos sed. Nulla incidunt ut illum sed velit voluptatem.
          Tempora saepe quis dolore corporis. Qui rerum voluptatum hic quae
          nihil. Architecto deserunt odio nostrum molestiae. Molestiae soluta
          nemo. Labore voluptas quisquam occaecati qui aliquam quae illo. Sequi
          rerum dolores doloremque quia. Eius magnam dolor architecto id. Sint
          nam aut neque molestiae provident quo. Aperiam quo beatae deleniti. Et
          necessitatibus aut hic aliquam voluptatem ab quo natus illo. Et qui
          eos velit exercitationem. Consequuntur harum molestias velit doloribus
          veniam. Libero quidem soluta. Necessitatibus ut occaecati illo enim
          eum delectus eos. Vel ex perspiciatis corrupti eaque. Quis eligendi
          quae possimus natus enim eum aut quae. Fuga consequatur et. Iusto
          debitis quam fuga nulla eveniet quos sed. Nulla incidunt ut illum sed
          velit voluptatem. Tempora saepe quis dolore corporis. Qui rerum
          voluptatum hic quae nihil. Architecto deserunt odio nostrum molestiae.
          Molestiae soluta nemo. Libero autem ut suscipit ratione ea
          necessitatibus suscipit praesentium. Aut veritatis dicta laborum
          exercitationem omnis omnis nam esse. Aut nihil ab voluptatem nulla hic
          non eum ut ut. Facere optio esse recusandae. Aperiam iusto accusantium
          mollitia ex quas id et doloremque repellendus. Qui qui incidunt minus
          eum officiis libero nesciunt qui aliquid. Labore voluptas quisquam
          occaecati qui aliquam quae illo. Sequi rerum dolores doloremque quia.
          Eius magnam dolor architecto id. Sint nam aut neque molestiae
          provident quo. Aperiam quo beatae deleniti. Et necessitatibus aut hic
          aliquam voluptatem ab quo natus illo. Et qui eos velit exercitationem.
          Consequuntur harum molestias velit doloribus veniam. Libero quidem
          soluta. Labore voluptas quisquam occaecati qui aliquam quae illo.
          Sequi rerum dolores doloremque quia. Eius magnam dolor architecto id.
          Sint nam aut neque molestiae provident quo. Aperiam quo beatae
          deleniti. Et necessitatibus aut hic aliquam voluptatem ab quo natus
          illo. Et qui eos velit exercitationem. Consequuntur harum molestias
          velit doloribus veniam. Libero quidem soluta.`,
  },
  {
    id: 2,
    title: "Les erreurs fréquentes à éviter pour éviter les amendes",
    desc: "Vous pensez tout savoir sur les amendes ? Détrompez-vous ! Découvrez les erreurs les plus courantes commises par les conducteurs et apprenez comment les éviter pour rester en règle sur la route. De la vitesse excessive au stationnement interdit, explorez les principales infractions à éviter pour préserver votre permis et votre portefeuille.",
    fullDesc: `          Comment éviter les pièges à points : Top 5 des infractions méconnues
          Unde maiores iusto architecto repellat veniam expedita unde nihil.
          Voluptatem in dolorem rerum ex consequatur. In quod ducimus. Quibusdam
          illo reiciendis. Maiores rem distinctio aut dolorem id dolorum rerum.
          Necessitatibus ut occaecati illo enim eum delectus eos. Vel ex
          perspiciatis corrupti eaque. Quis eligendi quae possimus natus enim
          eum aut quae. Fuga consequatur et. Iusto debitis quam fuga nulla
          eveniet quos sed. Nulla incidunt ut illum sed velit voluptatem.
          Tempora saepe quis dolore corporis. Qui rerum voluptatum hic quae
          nihil. Architecto deserunt odio nostrum molestiae. Molestiae soluta
          nemo. Necessitatibus ut occaecati illo enim eum delectus eos. Vel ex
          perspiciatis corrupti eaque. Quis eligendi quae possimus natus enim
          eum aut quae. Fuga consequatur et. Iusto debitis quam fuga nulla
          eveniet quos sed. Nulla incidunt ut illum sed velit voluptatem.
          Tempora saepe quis dolore corporis. Qui rerum voluptatum hic quae
          nihil. Architecto deserunt odio nostrum molestiae. Molestiae soluta
          nemo. Labore voluptas quisquam occaecati qui aliquam quae illo. Sequi
          rerum dolores doloremque quia. Eius magnam dolor architecto id. Sint
          nam aut neque molestiae provident quo. Aperiam quo beatae deleniti. Et
          necessitatibus aut hic aliquam voluptatem ab quo natus illo. Et qui
          eos velit exercitationem. Consequuntur harum molestias velit doloribus
          veniam. Libero quidem soluta. Necessitatibus ut occaecati illo enim
          eum delectus eos. Vel ex perspiciatis corrupti eaque. Quis eligendi
          quae possimus natus enim eum aut quae. Fuga consequatur et. Iusto
          debitis quam fuga nulla eveniet quos sed. Nulla incidunt ut illum sed
          velit voluptatem. Tempora saepe quis dolore corporis. Qui rerum
          voluptatum hic quae nihil. Architecto deserunt odio nostrum molestiae.
          Molestiae soluta nemo. Libero autem ut suscipit ratione ea
          necessitatibus suscipit praesentium. Aut veritatis dicta laborum
          exercitationem omnis omnis nam esse. Aut nihil ab voluptatem nulla hic
          non eum ut ut. Facere optio esse recusandae. Aperiam iusto accusantium
          mollitia ex quas id et doloremque repellendus. Qui qui incidunt minus
          eum officiis libero nesciunt qui aliquid. Labore voluptas quisquam
          occaecati qui aliquam quae illo. Sequi rerum dolores doloremque quia.
          Eius magnam dolor architecto id. Sint nam aut neque molestiae
          provident quo. Aperiam quo beatae deleniti. Et necessitatibus aut hic
          aliquam voluptatem ab quo natus illo. Et qui eos velit exercitationem.
          Consequuntur harum molestias velit doloribus veniam. Libero quidem
          soluta. Labore voluptas quisquam occaecati qui aliquam quae illo.
          Sequi rerum dolores doloremque quia. Eius magnam dolor architecto id.
          Sint nam aut neque molestiae provident quo. Aperiam quo beatae
          deleniti. Et necessitatibus aut hic aliquam voluptatem ab quo natus
          illo. Et qui eos velit exercitationem. Consequuntur harum molestias
          velit doloribus veniam. Libero quidem soluta.`,
  },
  {
    id: 3,
    title:
      "Les conséquences des infractions au code de la route sur votre permis",
    desc: "Saviez-vous que chaque infraction au code de la route peut avoir un impact sur votre permis ? Explorez cet article pour comprendre les conséquences possibles de vos actions sur la route et comment les éviter. De la perte de points à la suspension du permis, découvrez les différentes sanctions encourues en cas d'infraction et comment les prévenir.",
    fullDesc: `          Comment éviter les pièges à points : Top 5 des infractions méconnues
          Unde maiores iusto architecto repellat veniam expedita unde nihil.
          Voluptatem in dolorem rerum ex consequatur. In quod ducimus. Quibusdam
          illo reiciendis. Maiores rem distinctio aut dolorem id dolorum rerum.
          Necessitatibus ut occaecati illo enim eum delectus eos. Vel ex
          perspiciatis corrupti eaque. Quis eligendi quae possimus natus enim
          eum aut quae. Fuga consequatur et. Iusto debitis quam fuga nulla
          eveniet quos sed. Nulla incidunt ut illum sed velit voluptatem.
          Tempora saepe quis dolore corporis. Qui rerum voluptatum hic quae
          nihil. Architecto deserunt odio nostrum molestiae. Molestiae soluta
          nemo. Necessitatibus ut occaecati illo enim eum delectus eos. Vel ex
          perspiciatis corrupti eaque. Quis eligendi quae possimus natus enim
          eum aut quae. Fuga consequatur et. Iusto debitis quam fuga nulla
          eveniet quos sed. Nulla incidunt ut illum sed velit voluptatem.
          Tempora saepe quis dolore corporis. Qui rerum voluptatum hic quae
          nihil. Architecto deserunt odio nostrum molestiae. Molestiae soluta
          nemo. Labore voluptas quisquam occaecati qui aliquam quae illo. Sequi
          rerum dolores doloremque quia. Eius magnam dolor architecto id. Sint
          nam aut neque molestiae provident quo. Aperiam quo beatae deleniti. Et
          necessitatibus aut hic aliquam voluptatem ab quo natus illo. Et qui
          eos velit exercitationem. Consequuntur harum molestias velit doloribus
          veniam. Libero quidem soluta. Necessitatibus ut occaecati illo enim
          eum delectus eos. Vel ex perspiciatis corrupti eaque. Quis eligendi
          quae possimus natus enim eum aut quae. Fuga consequatur et. Iusto
          debitis quam fuga nulla eveniet quos sed. Nulla incidunt ut illum sed
          velit voluptatem. Tempora saepe quis dolore corporis. Qui rerum
          voluptatum hic quae nihil. Architecto deserunt odio nostrum molestiae.
          Molestiae soluta nemo. Libero autem ut suscipit ratione ea
          necessitatibus suscipit praesentium. Aut veritatis dicta laborum
          exercitationem omnis omnis nam esse. Aut nihil ab voluptatem nulla hic
          non eum ut ut. Facere optio esse recusandae. Aperiam iusto accusantium
          mollitia ex quas id et doloremque repellendus. Qui qui incidunt minus
          eum officiis libero nesciunt qui aliquid. Labore voluptas quisquam
          occaecati qui aliquam quae illo. Sequi rerum dolores doloremque quia.
          Eius magnam dolor architecto id. Sint nam aut neque molestiae
          provident quo. Aperiam quo beatae deleniti. Et necessitatibus aut hic
          aliquam voluptatem ab quo natus illo. Et qui eos velit exercitationem.
          Consequuntur harum molestias velit doloribus veniam. Libero quidem
          soluta. Labore voluptas quisquam occaecati qui aliquam quae illo.
          Sequi rerum dolores doloremque quia. Eius magnam dolor architecto id.
          Sint nam aut neque molestiae provident quo. Aperiam quo beatae
          deleniti. Et necessitatibus aut hic aliquam voluptatem ab quo natus
          illo. Et qui eos velit exercitationem. Consequuntur harum molestias
          velit doloribus veniam. Libero quidem soluta.`,
  },
  {
    id: 4,
    title: "Le guide ultime pour contester une amende",
    desc: "Vous avez reçu une amende injustifiée ? Ne paniquez pas ! Ce guide complet vous explique étape par étape comment contester une amende et défendre vos droits en tant que conducteur. De la collecte des preuves à la rédaction de la lettre de contestation, suivez nos conseils pour augmenter vos chances de succès et faire valoir vos droits sur la route.",
    fullDesc: `          Comment éviter les pièges à points : Top 5 des infractions méconnues
          Unde maiores iusto architecto repellat veniam expedita unde nihil.
          Voluptatem in dolorem rerum ex consequatur. In quod ducimus. Quibusdam
          illo reiciendis. Maiores rem distinctio aut dolorem id dolorum rerum.
          Necessitatibus ut occaecati illo enim eum delectus eos. Vel ex
          perspiciatis corrupti eaque. Quis eligendi quae possimus natus enim
          eum aut quae. Fuga consequatur et. Iusto debitis quam fuga nulla
          eveniet quos sed. Nulla incidunt ut illum sed velit voluptatem.
          Tempora saepe quis dolore corporis. Qui rerum voluptatum hic quae
          nihil. Architecto deserunt odio nostrum molestiae. Molestiae soluta
          nemo. Necessitatibus ut occaecati illo enim eum delectus eos. Vel ex
          perspiciatis corrupti eaque. Quis eligendi quae possimus natus enim
          eum aut quae. Fuga consequatur et. Iusto debitis quam fuga nulla
          eveniet quos sed. Nulla incidunt ut illum sed velit voluptatem.
          Tempora saepe quis dolore corporis. Qui rerum voluptatum hic quae
          nihil. Architecto deserunt odio nostrum molestiae. Molestiae soluta
          nemo. Labore voluptas quisquam occaecati qui aliquam quae illo. Sequi
          rerum dolores doloremque quia. Eius magnam dolor architecto id. Sint
          nam aut neque molestiae provident quo. Aperiam quo beatae deleniti. Et
          necessitatibus aut hic aliquam voluptatem ab quo natus illo. Et qui
          eos velit exercitationem. Consequuntur harum molestias velit doloribus
          veniam. Libero quidem soluta. Necessitatibus ut occaecati illo enim
          eum delectus eos. Vel ex perspiciatis corrupti eaque. Quis eligendi
          quae possimus natus enim eum aut quae. Fuga consequatur et. Iusto
          debitis quam fuga nulla eveniet quos sed. Nulla incidunt ut illum sed
          velit voluptatem. Tempora saepe quis dolore corporis. Qui rerum
          voluptatum hic quae nihil. Architecto deserunt odio nostrum molestiae.
          Molestiae soluta nemo. Libero autem ut suscipit ratione ea
          necessitatibus suscipit praesentium. Aut veritatis dicta laborum
          exercitationem omnis omnis nam esse. Aut nihil ab voluptatem nulla hic
          non eum ut ut. Facere optio esse recusandae. Aperiam iusto accusantium
          mollitia ex quas id et doloremque repellendus. Qui qui incidunt minus
          eum officiis libero nesciunt qui aliquid. Labore voluptas quisquam
          occaecati qui aliquam quae illo. Sequi rerum dolores doloremque quia.
          Eius magnam dolor architecto id. Sint nam aut neque molestiae
          provident quo. Aperiam quo beatae deleniti. Et necessitatibus aut hic
          aliquam voluptatem ab quo natus illo. Et qui eos velit exercitationem.
          Consequuntur harum molestias velit doloribus veniam. Libero quidem
          soluta. Labore voluptas quisquam occaecati qui aliquam quae illo.
          Sequi rerum dolores doloremque quia. Eius magnam dolor architecto id.
          Sint nam aut neque molestiae provident quo. Aperiam quo beatae
          deleniti. Et necessitatibus aut hic aliquam voluptatem ab quo natus
          illo. Et qui eos velit exercitationem. Consequuntur harum molestias
          velit doloribus veniam. Libero quidem soluta.`,
  },
];
