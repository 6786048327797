import * as React from "react";
import styled from "styled-components";
import { palette } from "../styles/globalStyle";
import logo from "../assets/icons/websiteLogo.svg";
import gov from "../assets/icons/gov.svg";
import arrow from "../assets/arrow.svg";
import feat1 from "../assets/pictures/feat1.webp";
import feat2 from "../assets/pictures/feat2.webp";
import feat3 from "../assets/pictures/feat3.webp";
import feat4 from "../assets/pictures/feat4.webp";
import {
  TextIndent,
  MagnifyingGlass,
  Camera,
  Files,
  CheckCircle,
} from "@phosphor-icons/react";

function FeaturesSection() {
  return (
    <FeatureSectionWrapper id="features">
      <Container id="feature-1">
        <Title>Visualisez vos points de permis en un clin d'œil</Title>
        <Description>
          GérerMonPermis s'occupe de tout ! Suivez votre solde de points en
          temps réel grâce à un affichage clair et intuitif. Visualisez l'impact
          de vos actions avec des graphiques explicites et évitez les mauvaises
          surprises. Conduisez l'esprit tranquille en sachant exactement où vous
          en êtes.
        </Description>
        <FeaturesStep>
          <Feature>
            <img
              src={gov}
              alt="website logo svg"
              style={{ width: "40px", height: "40px" }}
            />
            <FeatureText>
              Créer votre compte sur permisdeconduire.interieur.gouv.fr
            </FeatureText>
          </Feature>
          <Arrow src={arrow} alt="website logo svg" />
        </FeaturesStep>
        <FeaturesStep>
          <Feature>
            <img
              src={logo}
              alt="website logo svg"
              style={{ width: "40px", height: "40px" }}
            />
            <FeatureText>Se connecter sur notre application</FeatureText>
            <ReversedArrow src={arrow} alt="website logo svg" />
          </Feature>
        </FeaturesStep>
        <FeaturesStep>
          <Feature>
            <CheckCircle color={palette.Primary[500]} weight="bold" size={40} />
            <FeatureText>Vérifier vos points</FeatureText>
          </Feature>
        </FeaturesStep>
        <img
          src={feat1}
          alt="website logo svg"
          style={{ width: "100%", marginTop: 28 }}
        />
      </Container>
      <Container id="feature-2">
        <Title>Organisez vos amendes facilement avec GérerMonPermis.</Title>
        <Description>
          Notre application vous permet de trier et d’organiser les amendes en
          fonction de leur état (payée, impayée, contestée) et d’ajouter des
          photos des amendes. Vous pouvez facilement retrouver les amendes que
          vous recherchez.
        </Description>
        <OptionContainer>
          <OptionIcon>
            <TextIndent color={palette.Primary[500]} weight="bold" size={30} />
          </OptionIcon>
          <OptionText>
            Triez vos amendes par date, montant ou statut.
          </OptionText>
        </OptionContainer>
        <OptionContainer>
          <OptionIcon>
            <MagnifyingGlass
              color={palette.Primary[500]}
              weight="bold"
              size={30}
            />
          </OptionIcon>
          <OptionText>
            Trouver facilement les amendes que vous cherchez.
          </OptionText>
        </OptionContainer>
        <OptionContainer>
          <OptionIcon>
            <Camera color={palette.Primary[500]} weight="bold" size={30} />
          </OptionIcon>
          <OptionText>
            Exploitez la reconnaissance optique de caractères pour extraire
            automatiquement les informations de vos amendes
          </OptionText>
        </OptionContainer>
        <OptionContainer>
          <OptionIcon>
            <Files color={palette.Primary[500]} weight="bold" size={30} />
          </OptionIcon>
          <OptionText>Stockez vos amendes dans le cloud.</OptionText>
        </OptionContainer>

        <img
          src={feat2}
          alt="website logo svg"
          style={{ width: "100%", marginTop: 28 }}
        />
      </Container>
      <Container id="feature-3">
        <Title>Gérez vos amendes en toute simplicité</Title>
        <Description>
          Gérer vos amendes n’a jamais été aussi simple. Avec notre application,
          vous pouvez ajouter des amendes rapidement grâce à la reconnaissance
          optique de caractères (OCR). Restez organisé et gérez vos amendes en
          toute simplicité avec GérerMonPermis.
        </Description>
        <OptionContainer>
          <OptionCircleIcon>
            <CheckCircle color={palette.Base.White} weight="bold" size={40} />
          </OptionCircleIcon>
          <OptionText>Payer vos amendes rapidement et facilement</OptionText>
        </OptionContainer>
        <OptionContainer>
          <OptionCircleIcon>
            <CheckCircle color={palette.Base.White} weight="bold" size={40} />
          </OptionCircleIcon>
          <OptionText>Contester vos amendes en toute simplicité</OptionText>
        </OptionContainer>
        <OptionContainer>
          <OptionCircleIcon>
            <CheckCircle color={palette.Base.White} weight="bold" size={40} />
          </OptionCircleIcon>
          <OptionText>
            Recevoir des notifications de rappels pour éviter les majorations
          </OptionText>
        </OptionContainer>
        <img
          src={feat3}
          alt="website logo svg"
          style={{ width: "100%", marginTop: 28 }}
        />
      </Container>
      <Container id="feature-4">
        <Title>Gérez facilement tous vos véhicules en un seul endroit</Title>
        <Description>
          Centralisez et gérez les informations de tous vos véhicules, qu'il
          s'agisse de voitures, de motos, ou autres. Programmez des rappels pour
          les vidanges et autres échéances importantes et consultez les amendes
          associées à chaque véhicule pour une gestion simplifiée.
        </Description>
        <OptionText>
          • Ajoutez et gérez vos voitures, motos, camions, etc.
        </OptionText>
        <OptionText>
          • Ne manquez plus aucune échéance ! Organisez-vous avec des rappels
          pour les vidanges, les contrôles techniques, et toutes les autres
          tâches importantes liées à votre véhicule.
        </OptionText>
        <OptionText>
          • Accédez rapidement aux amendes correspondant à chaque véhicule
        </OptionText>
        <img
          src={feat4}
          alt="website logo svg"
          style={{ width: "100%", marginTop: 28 }}
        />
      </Container>
    </FeatureSectionWrapper>
  );
}

export default FeaturesSection;
// const CardContainer = styled.div`
//   align-self: stretch;
//   display: flex;
//   gap: 8px;
//   font-size: 18px;
//   color: #1776e5;
//   font-weight: 500;
//   line-height: 156%;

//   @media (max-width: 991px) {
//     //flex-wrap: wrap;
//   }
// `;

// const CardIcon = styled.img`
//   width: 48px;
//   aspect-ratio: 1;
//   object-fit: cover;
//   object-position: center;
// `;

// const CardText = styled.p`
//   margin: auto 0;
//   font-family: Roboto, sans-serif;
//   text-transform: capitalize;
//   flex-grow: 1;

//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

const OptionIcon = styled.div`
  background-color: #c7def9;
  border-radius: 4.5px;
  padding: 8px;
  display: flex;
  height: auto;
`;
const OptionCircleIcon = styled.div`
  background-color: #1776e5;
  border-radius: 40px;
  padding: 8px;
  display: flex;
  height: auto;
`;

const OptionText = styled.p`
  color: #1776e5;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0 8px;
  text-align: start;
  width: 100%;
  @media (max-width: 780px) {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */ /* 114.286% */
  }
`;

const OptionContainer = styled.div`
  display: flex;
  padding: 8px;

  align-items: center;

  flex-direction: row;
  @media (max-width: 780px) {
    width: 100%;
  }
`;

const FeatureSectionWrapper = styled.section`
  background-color: #e8f1fc;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 64px 10%;
  @media (max-width: 1036px) {
    padding: 40px 32px;
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 40px 24px 24px;
  background-color: #f7f9fa;
  border-radius: 32px;
  width: 43%;

  @media (max-width: 1036px) {
    padding: 40px 16px;
  }
  @media (max-width: 780px) {
    align-items: center;
    width: 100%;
  }
`;
const Arrow = styled.img`
  position: absolute;
  width: 22px;
  left: 0;
  top: 70%;
  z-index: 1;
  @media (max-width: 991px) {
  }
`;
const ReversedArrow = styled.img`
  position: absolute;
  width: 22px;
  right: 0;
  top: 70%;
  z-index: 1;
  transform: scaleX(-1);
  @media (max-width: 991px) {
  }
`;
const Title = styled.h2`
  font: 700 32px/40px Poppins, sans-serif;
  letter-spacing: -0.64px;
  text-align: center;
  color: #0a3567;
  text-align: start;
  margin: 0;
  @media (max-width: 780px) {
  }
`;

const Description = styled.p`
  font: 400 18px/28px Roboto, sans-serif;
  text-align: center;
  color: var(--Base-Black, #222426);
  margin-top: 24px;
  text-align: start;
  @media (max-width: 991px) {
  }
`;

const FeaturesStep = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  margin-top: 24px;
  background-color: #c7def9;
  border: 2px solid rgba(155, 196, 244, 1);
  border-radius: 16px;
  font-size: 18px;
  color: #222426;
  font-weight: 500;
  line-height: 156%;
  flex-grow: 1;
  position: relative;
  @media (max-width: 780px) {
    flex-direction: column;
    padding: 8px 16px;
    width: 90%;
  }
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
  width: 100%;
  flex-grow: 1;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const FeatureText = styled.span`
  font: 500 18px/28px Roboto, sans-serif;
  text-align: start;
  @media (max-width: 780px) {
    font: 600 12px/14px Roboto, sans-serif;
  }
`;
