import React, { useEffect, useState } from "react";
import styled from "styled-components";
import googleplayIcon from "../assets/icons/googleplay.svg";
import appleIcon from "../assets/icons/apple.svg";

function HowToUseSection() {
  const [selectedTab, setSelectedTab] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);
  const steps = [
    {
      num: 1,
      title: "Créez votre compte GérerMonPermis en quelques minutes",
      desc: "Remplissez simplement un formulaire avec vos informations personnelles.",
      selected: selectedTab === 1,
    },
    {
      num: 2,
      title: "Créez un compte ou Connectez-vous avec votre identifiant ANTS.",
      desc: "Établissez une connexion fluide en vous inscrivant ou en vous connectant avec votre compte ANTS pour synchroniser toutes les données essentielles de votre permis de conduire.",
      selected: selectedTab === 2,
    },
    {
      num: 3,
      title: "Scannez et Organisez vos Amendes et Véhicules",
      desc: "Scannez et enregistrez vos amendes en toute simplicité grâce à nos outils de numérisation. Transformez vos documents papier en données numériques en un instant. Enregistrez vos véhicules et programmez des rappels pour rester à jour avec les échéances importantes.",
      selected: selectedTab === 3,
    },
    {
      num: 4,
      title: "Suivez l'état de vos amendes et payez-les facilement",
      desc: "GérerMonPermis simplifie la gestion de vos amendes en vous offrant un contrôle total en quelques clics. Suivez l'évolution de leur statut, réglez-les aisément et contestez-les en cas de besoin, le tout en toute simplicité.",
      selected: selectedTab === 4,
    },
  ];
  return (
    <Wrapper id="howToUse">
      <ContentWrapper>
        <Title>Comment utiliser GérerMonPermis ?</Title>
        <Desc>
          Suivez ces étapes pour maîtriser rapidement toutes les fonctionnalités
          de GérerMonPermis et optimiser votre gestion des amendes et des
          rappels liés à votre permis de conduire.
        </Desc>
        <Content>
          {steps.map((step, index) => (
            <StepWrapper
              key={index}
              onClick={() => setSelectedTab(step.num)}
              style={{
                width: step.selected ? "50%" : null,
                flexGrow: step.selected ? 1 : null,
                border: !isMobile ? "2px solid #9bc4f4" : null,
                backgroundColor: !isMobile && step.selected ? "#c7def9" : null,
              }}
            >
              <StepNum
                style={{
                  backgroundColor:
                    !isMobile && step.selected ? "#1776e5" : "#c7def9",
                  color: !isMobile && step.selected ? "#e8f1fc" : "#1776e5",
                }}
              >
                {step.num.toString().padStart(2, "0")}
              </StepNum>
              {isMobile || step.selected ? (
                <TextWrapper>
                  <StepTitle>{step.title}</StepTitle>
                  <StepDesc>{step.desc}</StepDesc>
                  {/* {step.num === 2 && (
                    <Note>
                      * Nous tenons à vous rassurer que vos identifiants ANTS ne
                      seront en aucun cas sauvegardés dans nos bases de données.
                      Nous respectons votre confidentialité et nous nous
                      engageons à protéger vos données personnelles.
                    </Note>
                  )} */}
                </TextWrapper>
              ) : null}
            </StepWrapper>
          ))}
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
}

export default HowToUseSection;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 64px;
  justify-content: space-between;
  @media (max-width: 780px) {
  }
`;
const ContentWrapper = styled.div`
  /* position: relative; */
  margin: 80px 10%;
  display: flex;
  width: 100%;
  align-items: start;
  flex-direction: column;
  @media (max-width: 800px) {
    //  flex-direction: column;
    //padding: 24px;
  }
`;

const Title = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin: 0;
  text-align: start;
  @media (max-width: 780px) {
    color: #0a3567;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.56px;
  }
`;
const DownloadTxt = styled.p`
  color: #0a3567;
  width: 50%;
  text-align: start;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.48px;
  margin: 0;
  @media (max-width: 780px) {
    width: 100%;
    color: #0a3567;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.4px;
  }
`;
const Desc = styled.p`
  color: #222426;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  text-align: start;

  margin-top: 8px;
  @media (max-width: 780px) {
    color: #222426;
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

const StepWrapper = styled.div`
  display: flex;
  //width: 658px;
  // height: 317px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 32px;
  min-height: 300px;
  border: 2px solid #9bc4f4;
  flex-wrap: nowrap;
  @media (max-width: 780px) {
    border: 0;
    margin-top: 16px;
  }
`;
const DownloadWrapper = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  gap: 24px;
  border-radius: 32px;
  background-color: #c7def9;
  margin-top: 40px;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const Content = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  @media (max-width: 800px) {
    //flex-direction: column;

    justify-content: start;
    //padding: 24px;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: space-between;
  // width: 100%;
  // margin-top: 50px;
  @media (max-width: 780px) {
    border-radius: 32px;
    border: 2px solid #1776e5;
    padding: 16px;
    background-color: #c7def9;
    //flex-direction: column;
    // justify-content: start;
    //padding: 24px;
  }
`;

const StepNum = styled.p`
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.8px;
  padding: 8px;
  border-radius: 50px;
  aspect-ratio: 1;
  width: 50px;
  margin: 0;
`;

const StepTitle = styled.p`
  color: #222426;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.48px;
  text-align: start;
  margin: 0;
`;
const StepDesc = styled.p`
  margin: 0;
  color: #505459;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
`;
const Note = styled.p`
  margin: 0;
  margin-top: 30px;
  color: #a2a7a8;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: start;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 32px;
  gap: 16px;
  color: #f7f9fa;
  flex-wrap: wrap;
  @media (max-width: 780px) {
    flex-direction: column;
    margin: 8px 24px;
    width: 100%;
    align-self: center;
    justify-content: center;
  }
`;
const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 36px;
  align-self: start;
`;

const BtnTxtWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0%;
  @media (max-width: 991px) {
  }
`;
const BtnTxtDownload = styled.div`
  font: 500 18px/156% Roboto, sans-serif;
  line-height: 14px;
  color: #f7f9fa;
`;
const BtnStoreName = styled.div`
  letter-spacing: -0.48px;
  font: 700 24px/133% Poppins, sans-serif;
  color: #f7f9fa;
  @media (max-width: 991px) {
  }
`;
const BtnWrapper = styled.div`
  justify-content: flex-end;
  border-radius: 16px;
  background-color: #1776e5;
  display: flex;
  gap: 8px;
  padding: 16px;
  padding-bottom: 8px;
`;
